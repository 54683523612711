@import "config/variables";
@import "mixins/_mixins";

@font-face {
  font-family: "Source Sans Variable";
  src: url("fonts/source-sans-variable-roman-subset.woff2") format("woff2" supports variations);
  src: url("fonts/source-sans-variable-roman-subset.woff2") format("woff2");
  font-weight: 200 900;
}

html {
  box-sizing: border-box;
  font-size: 10px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
}

body.reporter-login {
  display: flex;
  align-items: stretch;
  margin: 0;
  font-size: 1.6rem;
  min-height: 100%;
  background: #fafafa;
  font-family: "Source Sans Variable", sans-serif;

  > section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 3rem;
    min-height: 100vh;
    &.venue-selection {
      div {
        max-width: 80rem;
        text-align: center;
      }

      a {
        color: unset;
        text-decoration: none;

        h3 span {
          display: block;
          opacity: 0.3;
        }
      }
    }

    div {
      margin-bottom: 5%;

      figure {
        display: flex;
        justify-content: center;
        margin: 0 0 2rem;

        img {
          width: 20rem;
        }
      }

      p {
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: center;
      }

      .verification-form {
        @include small-down {
          margin: 1rem;
          padding: 2rem 3rem;
          min-width: unset;
          max-width: 33.4rem;
          h1 {
            margin-top: 1.5rem;
            margin-bottom: 1rem;
          }
          p {
            font-size: 1.4rem;
          }
        }

        text-align: center;
        background-color: white;
        box-shadow: 0 0.3rem 0.8rem rgba(0,0,0,0.1);
        padding: 2rem 10rem;
        min-height: 36.5rem;
        min-width: 60rem;
        h1 {
          border-top: unset;
          margin-top: 2.9rem;
          margin-bottom: 2rem;
        }

        a {
          color: grey;
        }
      }

      .login-form {
        text-align: center;
        background-color: white;
        box-shadow: 0 0.3rem 0.8rem rgba(0,0,0,0.1);
        padding: 2rem 15rem;
        max-width: 60rem;
        min-height: 36.5rem;

        h1 {
          border-top: unset;
          font-size: 3.5rem;
          margin-bottom: 2rem;
          margin-top: 0;
        }

        @include small-down {
          margin: 1rem;
          padding: 2rem 3rem;
          h1 {
            margin-top: 1.5rem;
            margin-bottom: 1rem;
          }

          form {
            max-width: 30rem;
            margin: 0 auto;
          }
        }

        form {
          i {
            line-height: 4.1rem;
          }

          input {
            display: block;
            padding: 1.2rem 1.6rem;
            width: 100%;
            margin: 0 0 1.6rem;
            border: 0.2rem solid #fafafa;
            box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.15);
            font-size: 1.6rem;
            border-radius: 0.6rem;
          }

          input[type="text"],
          input[type="password"] {
            box-sizing: border-box;
            margin-right: 15rem;
            padding-right: 4rem;
            &:focus {
              border-color: #333;
              outline: none;
            }
          }
          input[type="submit"] {
            margin-top: 2rem;
            padding: 1.2rem 2rem 1.5rem;
            background: #333;
            background: linear-gradient(135deg, #555 30%, #111 100%);
            box-shadow: inset 0 -0.4rem 0 rgba(0, 0, 0, 0.3);
            background-size: 200% 100%;
            background-position: 80% 100%;
            transition: all 0.1s;
            color: white;
            font-weight: 650;
            transition: 125ms all ease-in-out;
            cursor: pointer;
            &:hover,
            &:focus {
              background-position: 50% 100%;
              outline: none;
            }
          }
        }
      }

      h1 {
        margin: 3rem 0 1rem;
        padding-top: 2.5rem;
        border-top: 0.2rem solid #eee;
        font-size: 3rem;
        font-weight: 600;
      }

      .venues {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .venue {
          margin: 1rem;
          padding: 2.5rem 3rem;
          width: 35rem;
          background: white;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          border-radius: 0.5rem;
          @include mobile-only() {
            width: 25rem;
          }

          h3 {
            margin-top: 0;
            font-weight: 600;
          }

          button {
            padding: 1.2rem 2rem 1.5rem;
            background: #333;
            background: linear-gradient(135deg, #555 30%, #111 100%);
            box-shadow: inset 0 -0.4rem 0 rgba(0, 0, 0, 0.3);
            background-size: 200% 100%;
            background-position: 80% 100%;
            transition: all 0.1s;
            width: 100%;
            color: white;
            font-weight: 650;
            outline: 0;
            border: 0;
            cursor: pointer;
            font-size: 1.5rem;
            border-radius: 0.6rem;
            transition: 125ms all ease-in-out;
            &:hover,
            &:focus {
              background-position: 50% 100%;
              outline: none;
            }
          }
        }
      }
    }
  }
}
